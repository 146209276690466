body {
    background-color: #505050;
}

.copyright {
    color: white;
}

#form-fields {
    width: 29.615384615384617em; // 385px / 13px = 29.615384615384617em
    margin: 0 auto;
    padding: 1.5384615384615385em 3.076923076923077em 3.076923076923077em; // 20px / 13px = 1.5384615384615385em, 40px / 13px = 3.076923076923077em
    background: #ffffff;
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0 10px 40px rgba(0, 0, 0, 0.7), 0 0 25px inset rgba(255, 255, 255, 0.15);
    border-radius: 3px;

    @supports (backdrop-filter: blur(1px)) {
        background: rgba(255, 255, 255, 0.25);
        backdrop-filter: blur(50px);
    }

    > div + div {
        margin: 1.5384615384615385em 0 0; // 20px / 13px = 1.5384615384615385em
    }

    * {
        width: 100%;
    }

    input[type="text"],
    input[type="password"] {
        border: 1px solid #cccccc;
    }

    input[type="checkbox"] {
        width: auto;
        margin: 0 2px 0 0;
    }

    .remember-me-and-password-recovery {
        text-align: center;
        color: #ffffff;
        text-shadow: 0 1px 1px rgba(0, 0, 0, 1);


        label,
        a {
            color: #ffffff;
            display: inline;
            margin: 0 5px;
        }

        a {
            text-decoration: none;
        }

        label:hover,
        a:hover {
            color: #ffffff;
            cursor: pointer;
            text-decoration: underline;
        }
    }
}

.passwordResetPanel {
    h3 {
        display: inline;
        text-align: left;
        font-size: 2em;
        font-weight: 200;
        color: #0e2240;
    }

    button,
    input,
    p {
        margin-bottom: 0;
        margin-top: 20px;
        width: 100%;
    }

    input {
        border: 1px solid #efefef;
    }
}

input,
button {
    font-family: "Proxima Nova Soft W03 Reg", Helvetica, Arial, sans-serif;
    border: 0;
    padding: 1em; // 15px / 15px = 1em
    margin: 0;
    border-radius: 3px;
    font-size: 15px;
    font-size: 1.5rem; // 15px
}

button {
    color: #ffffff;
    background-color: rgba(0, 0, 0, 0.2);
    font-size: 16px;
    font-size: 1.6rem; // 16px
    transition: all 100ms ease-in;
    cursor: pointer;

    &:hover {
        background-color: rgba(152, 249, 255, 1);
        color: #555555;
    }
}

.logo {
    text-align: center;
    filter: drop-shadow(0 -1px 0 rgba(0, 0, 0, 0.4));
}

h1,
h2 {
    line-height: 1;
    text-align: center;
    color: #ffffff;
    text-shadow: 0 1px 1px rgba(100, 100, 100, 0.5);
    font-weight: normal;
    font-size: 60px;
    font-size: 6rem; // 60px
    margin: 10% 0 0;
    font-family: "Proxima N W01 Thin Reg", Helvetica, Arial, sans-serif;
    text-transform: uppercase;
}

h2 {
    font-size: 40px;
    font-size: 4rem; // 40px
    margin: 0.375em 0 0.5em; // 15px / 40px = 0.375em, 20px / 40px = 0.5em
    background: none;
}

.collaterate.login {
    width: 6.923076923076923em; // 90px / 13px = 6.923076923076923em
    height: 6.923076923076923em; // 90px / 13px = 6.923076923076923em
    float: none;
    margin: 0;
}

.service-description {
    text-align: center;
    color: #eeeeee;
    font-size: 19.5px;
    font-size: 1.95rem; // 1.5em --> 19.5px
    margin: 4.102564102564102em 0 0; // 80px / 19.5px = 4.102564102564102em
    font-family: "Proxima N W01 Thin Reg", Helvetica, Arial, sans-serif;
}
